import Image from 'next/image';
import { imageBlurDataUrl } from '@components';

type FooterProps = {
  logo?: {
    url: string;
    alt: string;
  };
  menu?: {
    url: string;
    title: string;
  }[];
};

export function Footer({ logo, menu }: FooterProps) {
  return (
    <div className='bg-gradient-to-br from-indigo-600 to bg-indigo-700 relative'>

      <footer className='text-indigo-100 px-4 md:px-6 lg:px-8 pt-8'>
        <div className='max-w-screen-xl mx-auto'>

          <div className='grid md:grid-cols-8 lg:grid-cols-6 gap-y-3 md:gap-y-0'>

            <div className='md:col-span-2 lg:col-span-1'>
              <div className='relative w-40 h-16'>
                <Image
                  alt={logo.alt}
                  src={logo.url}
                  loader={() => logo.url}
                  layout='fill'
                  objectFit='contain'
                  quality={100}
                  placeholder='blur'
                  blurDataURL={imageBlurDataUrl(100, 100)}
                />
              </div>
            </div>

            <div className='md:col-span-6 lg:col-span-5'>
              <p className='text-gray-200 font-normal text-sm md:text-base'>
                The listings featured on this site are from
                companies from which this site receives
                compensation. This influences where, how
                and in what order such listings appear on
                this site.
              </p>

              <ul className='flex flex-wrap gap-x-4 md:gap-x-8 mt-4'>
                {
                  menu.map(({ title, url }) => (
                    <li
                      key={title}
                      className='mb-2'
                    >
                      <a
                        className='font-normal text-sm md:text-base hover:text-gray-400'
                        href={url}
                      >
                        {title}
                      </a>
                    </li>
                  ))
                }
              </ul>
            </div>

          </div>

          <div className='border-t-2 border-indigo-500 mt-4 py-4 md:py-6 z-10'>
            <div className='max-w-screen-xl mx-auto flex flex-col md:flex-row justify-center items-center text-indigo-100 '>

              <p className='text-sm md:text-center leading-6'>
                @ 2013 -
                {' '}
                {new Date().getFullYear()}
                {' '}
                Comparison411.com All Rights Reserved.
              </p>

            </div>
          </div>
        </div>
      </footer>

    </div>
  );
}

Footer.defaultProps = {
  logo: {
    alt: 'Comparison411 logo',
    url: './logo-white.svg',
  },
  menu: [
    {
      title: 'Privacy Policy',
      url: '/privacy-policy',
    },
    {
      title: 'Terms of Use',
      url: '/terms-of-use',
    },
    {
      title: 'Opt Out',
      url: '/optout',
    },
    {
      title: 'Contact',
      url: '/contact-us',
    },
  ],
};
