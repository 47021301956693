import { ArrowCircleRightIcon, SearchIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import { imageBlurDataUrl } from '@components';
import { SearchInput } from '@new-components';

type CompanyCardProps = {
    alt: string;
    url: string;
    siteUrl: string;
};

function CompanyCard({ alt, url, siteUrl }: CompanyCardProps) {
  return (
    <a
      className='bg-white px-2 py-1.5 lg:px-4 lg:py-2 rounded-md shadow-md grid grid-cols-6 items-center justify-center'
      href={siteUrl}
      title={alt}
      target='_blank'
      rel='noreferrer'

    >
      <div className='col-span-5'>
        <div className='relative w-full h-14 max-h-10 md:max-h-14'>
          <Image
            alt={alt}
            src={url}
            loader={() => url}
            layout='fill'
            objectFit='contain'
            quality={100}
            placeholder='blur'
            blurDataURL={imageBlurDataUrl(100, 100)}
          />
        </div>
      </div>

      <div className='flex flex-col items-center justify-center'>
        <div className='inline-block text-blue-500'>
          <ArrowCircleRightIcon className='w-7 h-7' />
        </div>
      </div>

    </a>
  );
}

type HeroProps = {
    companies?: { imageAlt: string; imageUrl: string; siteUrl: string }[],
    title?: string;
    content?: string;
    heroImageAlt?: string;
    heroImageUrl?: string;
    logo?: string;
    logoAlt?: string;
};

export function Hero({
  companies, content, heroImageAlt, heroImageUrl, logo, logoAlt, title,
}: HeroProps) {
  return (
    <header className='bg-gray-100 text-gray-600 px-6 lg:px-12 py-4 lg:py-12 relative overflow-hidden'>

      <img
        alt={heroImageAlt}
        className='absolute object-cover w-full inset-0 h-full'
        src={heroImageUrl}
      />

      <div className='bg-gradient-to-br from-blue-900 absolute inset-0'>
            &nbsp;
      </div>

      <div className='relative z-10'>
        <div className='space-y-12 pb-12 md:pb-16 lg:pb-24'>

          <div className='max-w-screen-xl mx-auto'>

            <div className='flex flex-col gap-y-8 md:gap-y-16 lg:gap-y-20'>

              <div className='w-full flex flex-col items-center gap-y-4 md:gap-y-0 md:flex-row md:justify-between'>
                <div className='relative w-40 h-14'>
                  <Image
                    alt={logoAlt}
                    src={logo}
                    loader={() => logo}
                    layout='fill'
                    objectFit='contain'
                    quality={100}
                    placeholder='blur'
                    blurDataURL={imageBlurDataUrl(100, 100)}
                  />
                </div>

                <div className='w-full md:w-max'>
                  <SearchInput
                    renderCustomButton={() => (
                      <button
                        className='inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded-r border-l-0 shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center'
                        type='submit'
                      >
                        <SearchIcon className='w-6 h-6' />
                      </button>
                    )}
                  />
                </div>
              </div>

              <div className='space-y-4 md:space-y-6 lg:space-y-10'>
                <h1 className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-white font-bold text-center md:text-left'>
                  {title}
                </h1>

                <p className='text-lg md:text-xl text-white max-w-3xl leading-relaxed '>
                  {content}
                </p>
              </div>

              <div>
                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 md:gap-x-4'>
                  {companies.map(({ imageAlt, imageUrl, siteUrl }) => (
                    <CompanyCard
                      key={imageAlt}
                      alt={imageAlt}
                      url={imageUrl}
                      siteUrl={siteUrl}
                    />
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className='absolute left-0 right-0 -bottom-2 hidden md:block'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 2400 100'
          className='text-gray-100 fill-current'
        >
          <path className='currentColor' d='M0,0c0,0,900,95,2400,0v100H0L0,0z' />
        </svg>
      </div>

    </header>
  );
}

Hero.defaultProps = {
  title: 'Top Brands, Top Reviews.',
  content: 'Comparison411 makes decisions easier for millions of users by narrowing options to the top review lists from the best brands available. We research and test thousands of products to create top lists for each category.',
  heroImageAlt: 'Comparison411 Hero',
  heroImageUrl: 'https://d3l9pgwma9u3kz.cloudfront.net/hero-images/main-page.jpg',
  logo: '/logo-white.svg',
  logoAlt: 'Comparison411',
  companies: [
    {
      imageAlt: 'Merchant Services',
      imageUrl: 'https://d3l9pgwma9u3kz.cloudfront.net/logos/leaders-merchant-services.png',
      siteUrl: '/merchant-services/reviews/leaders/',
    },
    {
      imageAlt: 'Vonage',
      imageUrl: 'https://d3l9pgwma9u3kz.cloudfront.net/logos/vonage.png',
      siteUrl: '/voip/reviews/vonage/',
    },
    {
      imageAlt: 'Wix',
      imageUrl: 'https://d3l9pgwma9u3kz.cloudfront.net/logos/wix.png',
      siteUrl: '/ecommerce/reviews/wix/',
    },
    {
      imageAlt: 'Gusto',
      imageUrl: 'https://d3l9pgwma9u3kz.cloudfront.net/logos/gusto.png',
      siteUrl: '/payroll/reviews/gusto-payroll-services/',
    },
    {
      imageAlt: 'Hello Fresh',
      imageUrl: 'https://d3l9pgwma9u3kz.cloudfront.net/logos/hello-fresh.png',
      siteUrl: '/meal-delivery/reviews/hellofresh-meal-delivery/',
    },
    {
      imageAlt: 'Monday.com',
      imageUrl: 'https://d3l9pgwma9u3kz.cloudfront.net/logos/monday-com.png',
      siteUrl: '/crm/reviews/mondaycom/',
    },
  ],
};
